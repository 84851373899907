import React from "react";
import picture from "../images/solhyttenhero.jpg"
import picture2 from "../images/AndetBillede.jpg"
import Carousel from 'react-bootstrap/Carousel';

function Galleri(){
    return (
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={picture}
              alt="1"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={picture2}
              alt="2"
            />
          </Carousel.Item>
        </Carousel>
      );
}
export default Galleri;