import React from "react";
import Navbar from "../navbar";
import Kort from "../kort";

function kortPage() {
    return (
      <div>
        <Navbar></Navbar>
        <Kort></Kort>
      </div>
    );
  }
  export default kortPage;