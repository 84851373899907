import React from "react";
import Navbar from "../navbar";


function Udlejning() {
    return (
      <div>
        <Navbar></Navbar>

      </div>
    );
  }
  export default Udlejning;