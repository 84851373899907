import React from "react";
import Navbar from "../navbar";
import "./about.css";

function About() {
    return (
                <div>
                <Navbar></Navbar>

                <div className="aboutHeadline">
                    <h1>Information</h1>                   
                </div>

                <div className="erfaringYdreDiv">

                  <div className="containerErfaring">
                  <div className="erfaringGrid col-6">

                    <div className="timeline">
                      <h4 className="textDeco">2012 <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> nu</h4>

                    </div>
                    <div className="innerFlexBox">
                      <h4 className="textDeco">Adm. dir, eGov Consulting</h4>
                      <p className="paragraphText">Startede eGov Consulting i 2012 og har siden da bl.a. løst konsulentopgaver for Erhvervsstyrelsen, Domstolsstyrelsen, Kriminalforsorgen, UCC, ATP og Region Hovedstadens IT-afdeling. Sidstnævnte som interim leder for 6 chefer og 200 medarbejdere. Carsten har i perioden bl.a. fungeret som it-udviklingschef, it-driftschef, it-supportchef, specifikatør og som projektleder (Prince2 og Scrum).</p>
                    </div>  

                    <div className="timeline textDeco">2007 <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> 2012</div>
                    <div className="innerFlexBox">
                      <h4 className="textDeco">Kontorchef, Erhvervsstyrelsen</h4>
                      <p className="paragraphText">Ansvarlig for virksomhedsportalen Virk.dk som giver virksomheder mulighed for at indberette elektronisk til den offentlige sektor via over 1.300 indberetningsløsninger. Gennemførte to 1½ års it-projekter succesfuldt. Medlem af diverse fællesoffentlige styregrupper og følgegrupper såsom Digital post, NemLogin, NemID, eDag3 mv. Personaleansvar for ca. 25 medarbejdere.</p>
                    </div>  

                    <div className="timeline textDeco">2003 <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> 2006</div>
                    <div className="innerFlexBox">
                      <h4 className="textDeco">Projektleder, Finansministeriet</h4>
                      <p className="paragraphText">Ansvarlig for en række projekter herunder "Digitaliseringsstrategien for den offentlige sektor 2007-2010". Fik besluttet at indføre administrative fællesskaber i staten, Digital Post, NemSMS, et eLæringssystem, et eIndkomst system og gennemførte et OECD review af digitalisering i Danmark.</p>
                    </div>  

                    <div className="timeline textDeco">1998 <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> 2003</div>
                    <div className="innerFlexBox">
                      <h4 className="textDeco">Analyst <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> Consultant <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> Manager, Acenture</h4>
                      <p className="paragraphText">Deltager i og ansvarlig for en række digitaliseringsprojekter hos Københavns Kommune, It- og Telestyrelsen, Sundhed.dk og diverse dot.com projekter.</p>
                    </div>   


                    </div>

                    <div className="erfaringGrid col-6">

                      <div className="timeline">
                        <h4 className="textDeco">2012 <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> nu</h4>

                      </div>
                      <div className="innerFlexBox">
                        <h4 className="textDeco">Adm. dir, eGov Consulting</h4>
                        <p className="paragraphText">Startede eGov Consulting i 2012 og har siden da bl.a. løst konsulentopgaver for Erhvervsstyrelsen, Domstolsstyrelsen, Kriminalforsorgen, UCC, ATP og Region Hovedstadens IT-afdeling. Sidstnævnte som interim leder for 6 chefer og 200 medarbejdere. Carsten har i perioden bl.a. fungeret som it-udviklingschef, it-driftschef, it-supportchef, specifikatør og som projektleder (Prince2 og Scrum).</p>
                      </div>  

                      <div className="timeline textDeco">2007 <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> 2012</div>
                      <div className="innerFlexBox">
                        <h4 className="textDeco">Kontorchef, Erhvervsstyrelsen</h4>
                        <p className="paragraphText">Ansvarlig for virksomhedsportalen Virk.dk som giver virksomheder mulighed for at indberette elektronisk til den offentlige sektor via over 1.300 indberetningsløsninger. Gennemførte to 1½ års it-projekter succesfuldt. Medlem af diverse fællesoffentlige styregrupper og følgegrupper såsom Digital post, NemLogin, NemID, eDag3 mv. Personaleansvar for ca. 25 medarbejdere.</p>
                      </div>  

                      <div className="timeline textDeco">2003 <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> 2006</div>
                      <div className="innerFlexBox">
                        <h4 className="textDeco">Projektleder, Finansministeriet</h4>
                        <p className="paragraphText">Ansvarlig for en række projekter herunder "Digitaliseringsstrategien for den offentlige sektor 2007-2010". Fik besluttet at indføre administrative fællesskaber i staten, Digital Post, NemSMS, et eLæringssystem, et eIndkomst system og gennemførte et OECD review af digitalisering i Danmark.</p>
                      </div>  

                      <div className="timeline textDeco">1998 <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> 2003</div>
                      <div className="innerFlexBox">
                        <h4 className="textDeco">Analyst <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> Consultant <svg className="rightArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Artboard-34</title><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style={{fill:"#232326"}}/></g></svg> Manager, Acenture</h4>
                        <p className="paragraphText">Deltager i og ansvarlig for en række digitaliseringsprojekter hos Københavns Kommune, It- og Telestyrelsen, Sundhed.dk og diverse dot.com projekter.</p>
                      </div>   


                    </div>


                    </div>

                </div>

              </div>
    );
  }
  export default About;