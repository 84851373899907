import React from "react";
import Navbar from "../navbar";
import Hero from "../hero"

function Homepage() {
    return (
      <div>
        <Navbar></Navbar>
        <Hero></Hero>
      </div>
    );
  }
  export default Homepage;