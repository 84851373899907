import React from "react";
import "./navbar.css"

function Navbar(){
    return (
        <div>
<nav class=" navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link" href="/">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/about">About</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/billeder">Billeder</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/kort">Kort</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/udlejning">Udlejning</a>
            </li>
        </ul>
    </div>
</nav>  
        </div>
    );
}

export default Navbar;