import React from "react";
import Navbar from "../navbar";
import Galleri from "../galleri";
import "./billeder.css"

function Billeder() {
    return (
      <div>
      <Navbar></Navbar>
      <div className="Heading">
      <h1>Billeder</h1>
      </div>


      <Galleri></Galleri>
      </div>
    );
  }
  export default Billeder;