import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import "./kort.css"

// Define the coordinates for the marker (Pilekæret 7, 2840 Gl. Holte, Denmark)
const markerCoordinates = [55.815539, 12.450883];

// Create a custom icon for the marker
const customIcon = new L.Icon({
  iconUrl: "../src/images/MapPin.svg", // Replace with the path to your custom marker icon
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const Kort = () => {
  return (
    <div className="map-container">
      <MapContainer center={markerCoordinates} zoom={15} style={{ height: '1000px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={markerCoordinates} icon={customIcon}>
          <Popup>
            Pilekæret 7, 2840 Gl. Holte, Denmark <br /> You can add your content here.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Kort;