import React from "react";
import "./hero.css";

function Hero(){
    return (
    <div>


<div class="hero-section">
    <div class="container mt-5">
        <h1 class="display-4">Solhytten</h1>
    </div>
</div>


    </div>);
}
export default Hero;