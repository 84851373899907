import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/pages/homepage';
import About from './components/pages/about';
import Billeder from './components/pages/billeder';
import KortPage from './components/pages/kortPage';
import Udlejning from './components/pages/udlejning';





function App() {
  return (
    <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/billeder" element={<Billeder />} />
        <Route path="/kort" element={<KortPage />} />
        <Route path="/udlejning" element={<Udlejning />} />


      </Routes>
    </div>
    </Router>
  );
}

export default App;
